import React from 'react'
import JobCreateComponent from '../../../components/employerDashboard/jobs/create'
import Layout from '../../../components/employerDashboard/layout'
const CreateJobPage = ({ location }) => {
  return (
    <Layout title="Create Job | MedReps.com" location={location}>
      <JobCreateComponent />
    </Layout>
  )
}
export default CreateJobPage
